<template>
	<div class="row">
		<div class="col-xl-3 col-lg-12">
			<AccountSidebar />
		</div>
		<div class="card card-custom col-xl-9 col-lg-12 tabs-container">
			<div v-if="adminAccount.loading" class="loader-overlay">
				<b-spinner label="Loading..." style="width: 3rem; height: 3rem; margin-right: 2rem"></b-spinner>
				<h3>Please wait, the account data is loading...</h3>
			</div>
			<b-tabs v-model="tabIndex">
				<b-tab>
					<template #title>
						<div class="aetabs">Account Info</div>
					</template>
					<AccountInfo
						:data="adminAccount.data"
						v-if="adminAccount && !adminAccount.loading && adminAccount.data && adminAccount.data.username"
						@save="loadAccountData()"
						:key="refresh"
					/>
				</b-tab>
				<b-tab lazy>
					<template #title><div class="aetabs">Change Image</div></template>
					<AccountChangeImage
						:data="adminAccount.data"
						v-if="adminAccount && !adminAccount.loading && adminAccount.data && adminAccount.data.username"
						@save="loadAccountData()"
						:key="refresh"
					/>
				</b-tab>
				<b-tab lazy>
					<template #title><div class="aetabs">Change Password</div></template>
					<AccountChangePassword
						:data="adminAccount.data"
						v-if="adminAccount && !adminAccount.loading && adminAccount.data && adminAccount.data.username"
					/>
				</b-tab>
				<b-tab v-if="adminAccount.role != 'video'" lazy>
					<template #title><div class="aetabs">Dashboard Layout</div></template>
					<DashboardLayout
						:data="adminAccount.data"
						v-if="adminAccount && adminAccount.data && adminAccount.data.layout"
						@save="loadAccountData()"
						:key="refresh"
					/>
				</b-tab>
				<b-tab v-if="adminAccount.role == 'user' || adminAccount.role == 'useradmin'" lazy>
					<template #title><div class="aetabs">Sites</div></template>
					<AccountSites v-if="adminAccount && adminAccount.data && adminAccount.data.layout" @save="loadAccountData()" />
				</b-tab>
				<b-tab v-if="adminAccount.role == 'user' || adminAccount.role == 'useradmin'" lazy>
					<template #title>
						<div class="aetabs">Emailed Reports</div>
					</template>
					<AccountEmailedReports @info="switchToInfo()" />
				</b-tab>
				<b-tab v-if="adminAccount.showapi && adminAccount.api_key && adminAccount.role != 'video'" lazy>
					<template #title><div class="aetabs">API</div></template>
					<AccountAPI :data="adminAccount.data" @save="loadAccountData()" :key="refresh" />
				</b-tab>
				<b-tab lazy>
					<template #title><div class="aetabs">Wearables</div></template>
					<AccountVideo />
				</b-tab>
				<b-tab lazy>
					<template #title>
						<div class="aetabs">Streams</div>
					</template>
					<AccountStreams :username="adminAccount.username" :userId="adminAccount.AccountID" />
				</b-tab>
			</b-tabs>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { SET_ADMIN_EDIT_ACCOUNT } from '@/core/services/store/admin.module';

export default {
	data() {
		return {
			refresh: 0,
			tabIndex: 0,
		};
	},
	props: ['id'],
	components: {
		AccountSidebar: () => import('@/view/content/widgets/admin/editaccount/AccountSidebar.vue'),
		AccountInfo: () => import('@/view/content/widgets/admin/editaccount/AccountInfo.vue'),
		AccountChangeImage: () => import('@/view/content/widgets/admin/editaccount/AccountChangeImage.vue'),
		AccountChangePassword: () => import('@/view/content/widgets/admin/editaccount/AccountChangePassword.vue'),
		DashboardLayout: () => import('@/view/content/widgets/admin/editaccount/DashboardLayout.vue'),
		AccountSites: () => import('@/view/content/widgets/admin/editaccount/AccountSites.vue'),
		AccountEmailedReports: () => import('@/view/content/widgets/admin/editaccount/AccountEmailedReports.vue'),
		AccountAPI: () => import('@/view/content/widgets/admin/editaccount/AccountAPI.vue'),
		AccountVideo: () => import('@/view/content/widgets/admin/editaccount/AccountVideo.vue'),
		AccountStreams: () => import('@/view/content/widgets/admin/editaccount/AccountStreams.vue'),
	},
	methods: {
		loadAccountData() {
			this.$store.dispatch(SET_ADMIN_EDIT_ACCOUNT, this.$route.params.id);
			this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Admin Edit Account: ' + this.$route.params.id }]);
			this.refresh++;
		},
		switchToInfo() {
			this.tabIndex = 0;
		},
	},
	mounted() {
		this.loadAccountData();
	},
	computed: {
		...mapGetters(['adminSite', 'currentUser', 'adminAccount']),
	},
	watch: {
		id() {
			this.loadAccountData();
		},
	},
};
</script>

<style scoped>
.aetabs {
	/* adjust padding for height*/
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.tabs-container {
  position: relative;
}

.loader-overlay {
  display: flex; /* Align items in a row */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center the flex container */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; /* Ensure it's above other content */
}

</style>
